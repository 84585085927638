import { Component, Inject } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-flamingo-input-dialog',
  template: `
    <h2 *ngIf="data.title" mat-dialog-title>{{ data.title }}</h2>
    <div mat-dialog-content>
      <form id="form" (submit)="onSubmit()">
        <p *ngIf="data.description">{{ data.description }}</p>
        <mat-form-field [appearance]="data.inputAppearance || 'fill'" style="width: 100%">
          <mat-label *ngIf="data.inputLabel">{{ data.inputLabel }}</mat-label>
          <input [formControl]="control" matInput [type]="data.inputType || 'text'"
                 [placeholder]="data.inputPlaceholder || ''">
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions [align]="'end'">
      <button [tabIndex]="-1" type="button" *ngIf="data.actionCancel" mat-button
              [mat-dialog-close]="undefined">{{ data.actionCancel }}
      </button>
      <button form="form" type="submit" mat-flat-button>{{ data.actionConfirm || 'OK' }}</button>
    </div>
  `,
  standalone: false,
})
export class FlamingoInputDialogComponent {

  control!: UntypedFormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FlamingoInputDialogOptions,
    public dialogRef: MatDialogRef<FlamingoInputDialogComponent>,
  ) {
    this.control = new UntypedFormControl(data.inputValue, data.inputValidators);
  }

  onSubmit(): void {
    if (this.control.invalid) {
      return;
    }
    this.dialogRef.close(this.control.value);
  }
}

export interface FlamingoInputDialogOptions {
  title?: string;
  description?: string;
  actionConfirm?: string;
  actionCancel?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  inputValue?: any;
  inputType?: string;
  inputAppearance?: MatFormFieldAppearance;
  inputValidators?: ValidatorFn | ValidatorFn[];
}
