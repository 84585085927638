import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-flamingo-alert-dialog',
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <div mat-dialog-content>
      <div [innerHTML]="description"></div>
    </div>
    <div mat-dialog-actions [align]="'end'">
      <button mat-button [mat-dialog-close]="undefined" class="btn-margin">{{ data.confirm }}</button>
    </div>
  `,
  standalone: false,
})
export class FlamingoAlertDialogComponent {
  description?: SafeHtml;

  constructor(
    sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<FlamingoAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FlamingoAlertDialogModel,
  ) {
    this.description = sanitizer.bypassSecurityTrustHtml(data.description);
  }

  close(res: boolean): void {
    this.dialogRef.close(res);
  }
}

export interface FlamingoAlertDialogModel {
  title: string;
  description: string;
  confirm: string;
}
